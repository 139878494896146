import { useEffect, useContext } from "react"
import { WBIContext } from '../../App'

import veilIdeas from '../../js/modules/veilIdeas.js'

function Pagination (props) {
  const {
      ideas,
      paginationAmount,
      paginatedIdeas, setpaginatedIdeas,
      paginatedIndex, setPaginatedIndex,
      paginated, setPaginated,
      setVeilDefault,
      setAllIdeasHidden,
      setRevealAllState,
      firebaseDBLoaded
  } = useContext(WBIContext)

  useEffect(() => {
    const setupPaginatedIdeas = () => {
      var counter = ideas.length

      ideas.forEach((idea, index) => {
          if (index === paginationAmount) { // first pagi
            // console.log('first pagi')
            setpaginatedIdeas(prevArray => [...prevArray, ideas.slice(0, (paginationAmount))])
            counter = counter - paginationAmount
          }
          else if (index > paginationAmount) {
            if (index % paginationAmount === 0 && index !== (index.length - 1)) { // Middle pagi
              setpaginatedIdeas(prevArray => [...prevArray, ideas.slice(index - (paginationAmount), index)])
              counter = counter - paginationAmount
              // console.log('middle pagi/s')
            }
          }

          if (index === (ideas.length - 1)) { // Final pagi
            // console.log('final pagi')
            setpaginatedIdeas(prevArray => [...prevArray, ideas.slice(((index+1) - counter), index.length)])
          }
      })
    }

    if (paginatedIdeas.length === 0) {
      if (props.paginate) {
        setupPaginatedIdeas()
      }
    }

  }, [paginatedIdeas, ideas, paginationAmount, setpaginatedIdeas])



  useEffect(() => {
    setpaginatedIdeas([])
  }, [firebaseDBLoaded, setpaginatedIdeas])

function paginationClickHandler (indexParam) {
  setPaginated(true)
  setPaginatedIndex(indexParam)
  veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
  window.scrollTo(0, 0)
}

function previousNext (PrevOrNext) {
  if (!paginated) {
    setPaginated(true)
  }

  if (PrevOrNext === 'previous') {
    if (paginatedIndex === 0) {
      setPaginatedIndex(paginatedIdeas.length-1)
    } else {
      setPaginatedIndex(paginatedIndex-1)
    }
  } else {
    if (paginatedIndex === (paginatedIdeas.length-1)) {
      setPaginatedIndex(0)
    } else {
      setPaginatedIndex(paginatedIndex+1)
    }
  }
  veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
  window.scrollTo(0, 0)
}

  const paginatedIdeasReturn = paginatedIdeas.map((idea, index) => {
    return (
        <li className={index === paginatedIndex ? "active" : null} key={index}>
          <button className={index === paginatedIndex ? "active" : null} data-index={index} onClick={() => {paginationClickHandler(index)}}>{index}</button>
        </li>
      )
  })

  return (
    <div className="pagination main">
      <div className="pagination-inner">

      <div className="paginationer">
        <ul>
          <li className="previous-btn">
            <button className="non-toggle" onClick={() => previousNext('previous')}>Previous</button>
          </li>
          {paginatedIdeasReturn}
          <li className="next-btn">
            <button className="non-toggle" onClick={() => previousNext('next')}>Next</button>
          </li>
        </ul>
      </div>

      </div>
    </div>
  )
}

export default Pagination