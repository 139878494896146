import { useContext } from "react"
import { WBIContext } from '../../App'

import loadingIcon from '../../images/loading2.gif'

function LoadingElement (props) {
    const {
      firebaseDBLoaded
    } = useContext(WBIContext)

    return (
        <>
          <div className='loading-latest-copy'>
            <h3>
                <span>{props.loadingText}</span> <img src={loadingIcon} className='loading-image' alt="" />
            </h3>
          </div>
        </>
      )
}

export default LoadingElement