import { createContext, useState, useEffect } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ideasData from './js/data/ideas'
import './css/styles.css'

import Main from './js/components/Main.js'

import Header from './js/components/Header.js'
import IdeasHeader from './js/components/IdeasHeader.js'

import Pagination from './js/components/Pagination.js'

import WhatisthisShit from './js/components/What-is-this-shit.js'

import ErrorElement from './js/components/Error-Element.js'

import LoadingElement from './js/components/Loading-Element.js'

import RandomPage from './js/components/Random-Page.js'

import veilIdeas from './js/modules/veilIdeas.js'
import clearSearch from './js/modules/clearSearch.js'

import UserPanel from './js/components/UserPanel.js'

import IdeasBody from './js/components/IdeasBody.js'

import { getDatabase, ref, onValue } from "firebase/database"

// import auth from './js/components/firebase'

export const WBIContext = createContext()

function App (props) {
    const location = useLocation()
    const navigate = useNavigate()
    // const user = auth.currentUser;

    const [ideas, setIdeas] = useState(ideasData.ideas)
    const [veilDefault, setVeilDefault] = useState(true)
    const [allIdeasHidden, setAllIdeasHidden] = useState(true)
    const [searchInput, setSearchInput] = useState('')
    const [filteredIdeas, setFilteredIdeas] = useState(ideasData.ideas)
    const [results, setResults] = useState(ideasData.ideas)
    const [revealAllState, setRevealAllState] = useState(false)
    const [common] = useState(ideasData.common)
    const [paginatedIdeas, setpaginatedIdeas] = useState([])
    const [paginationAmount, setPaginationAmount] = useState(ideasData.common.paginationAmount)
    const [paginated, setPaginated] = useState(false)
    const [paginatedIndex, setPaginatedIndex] = useState(0)
    const [random, setRandom] = useState(false)
    const [randomIdea, setRandomIdea] = useState(false)
    const [soundMuted, setSoundMuted] = useState(true)
    const [contributors, setContributors] = useState(ideasData.contributors)
    const [whatisthisshit, setWhatisthisshit] = useState(false)
    const [currentPage, setCurrentPage] = useState('/')
    const [sharing, setSharing] = useState(false)
    const [firebaseDBLoaded, setFirebaseDBLoaded] = useState(false)
    const [ugc, setUGC] = useState(false)
    const [login, setLogin] = useState(false)
    const [signup, setSignup] = useState(false)
    const [profile, setProfile] = useState(false)
    const [userPanelActive, setUserPanelActive] = useState(false)
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [signout, setSignout] = useState(false)
    const [loggingIn, setLoggingIn] = useState(false)
    const [comingSoon, setComingSoon] = useState(false)

    const useParamsRoute = useParams()

    function hideAll () {
        veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
    }

    function setRandoIdea () {
        var rndInt = Math.floor(Math.random() * (ideas.length - 1)) + 1
        setRandomIdea(ideas[rndInt])
    }

    function pathfinder () {
        let pathname = location.pathname
        var URLSplit
        if (!pathname.includes('undefined')) {
            if (pathname === '/' || typeof pathname == 'undefined') { // Home
                setCurrentPage('/')
                setSoundMuted(true)
                setRandom(false)
                setPaginated(true)
            } else if (pathname.includes('/tags/')) { // Loading page with tag-filtered ideas in place
            URLSplit = pathname.split("/");
            var tagWord = URLSplit[URLSplit.length - 1]
            setCurrentPage(pathname)
            setSearchInput(tagWord.split(":")[1])
            } else if (pathname.includes('/ideas/')) {
                // console.log('sharing idea refresh')
                URLSplit = useParamsRoute.id.split(":")[1]
                setCurrentPage(URLSplit)
                setSharing(true)
                setRandom(false)
                hideAll()
            } else if (pathname.includes('random')) {
                // console.log('PATHFINDER: navigate to random')
                setCurrentPage('random')
                setRandom(true)
                setSharing(false)
                hideAll()
                setRandoIdea()
                clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
                navigate('/random')
            } else if (pathname.includes('userpanel')) {
                setCurrentPage('login')
                setRandom(false)
                setSharing(false)
                setPaginated(false)
                hideAll()
                clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
                // setUserLoggedIn(true)
                navigate('/userpanel/login')
                setUserPanelActive(true)
            }
        } else { // pathname is undefined
            setCurrentPage('/')
        }
    }

    useEffect(() => {
        setSharing(false)
        setRandom(false)
        setPaginated(false)
        hideAll()
    }, [searchInput])

    useEffect(() => {
        if (searchInput.length === 0 || filteredIdeas.length ===  0) {
            setFilteredIdeas(ideas)
            setResults([])
        } else {
            window.scrollTo(0, 0)
        }
    }, [filteredIdeas, ideas, searchInput])

    useEffect(() => {
        if (allIdeasHidden) {
            veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
        }
    }, [allIdeasHidden])

    useEffect(() => {
        let listDuplicate = [...ideas].filter(
            (idea) => {
            return(
                idea.tags.indexOf(searchInput) !== -1
            )}
        )

        let listDuplicateHiddenTags = [...ideas].filter(
            (idea) => {
                return(
                    idea['hidden-tags'].indexOf(searchInput) !== -1
                )
            }
        )
        setFilteredIdeas(listDuplicate.concat(listDuplicateHiddenTags))
        setResults(listDuplicate.concat(listDuplicateHiddenTags))

        if (searchInput.length > 0) {
            setRandom(false)
        }
    }, [searchInput, ideas])

    useEffect(() => { // Page load

        window.onpopstate = e => { // On refresh
        pathfinder()
        }

        const db = getDatabase()
        const ideasRef = ref(db, 'ideas')
        onValue(ideasRef, (snapshot) => { // Assign firebase DB ideas to ideas state
            // =====================================
            // Firebase DB has loaded
            // Comment out to load ideas from local json: src/js/data/ideas.js
            setIdeas(snapshot.val())
            // =====================================
            setFirebaseDBLoaded(true)
        })

        pathfinder()
    }, [])

    return (
    <WBIContext.Provider value={{
        ideas, setIdeas,
        veilDefault, setVeilDefault,
        allIdeasHidden, setAllIdeasHidden,
        searchInput, setSearchInput,
        filteredIdeas, setFilteredIdeas,
        paginationAmount, setPaginationAmount,
        paginatedIdeas, setpaginatedIdeas,
        paginated, setPaginated,
        results, setResults,
        paginatedIndex, setPaginatedIndex,
        random, setRandom,
        randomIdea, setRandomIdea,
        revealAllState, setRevealAllState,
        contributors, setContributors,
        whatisthisshit, setWhatisthisshit,
        currentPage, setCurrentPage,
        sharing, setSharing,
        setFirebaseDBLoaded, firebaseDBLoaded,
        common,
        soundMuted, setSoundMuted,
        userPanelActive, setUserPanelActive,
        userLoggedIn, setUserLoggedIn,
        signup, setSignup,
        login, setLogin,
        signout, setSignout,
        loggingIn, setLoggingIn,
        comingSoon
    }}>
        <div className="App">
            <div className="App-inner">

                {/* Site banner */}
                <Header setRandoIdea={setRandoIdea} />

                <div className="container">
                    {/* Header */}
                    <div className="ideas-header">
                        { !firebaseDBLoaded ? <LoadingElement loadingText="LOADING IDEA/S" /> : null }
                        { (props.page !== ideasData.common["page-not-found-url"]) ? <IdeasHeader /> : <ErrorElement /> }
                    </div>

                    {(firebaseDBLoaded) ? <hr /> : null}

                    {userPanelActive ? <UserPanel /> : <IdeasBody />}
                </div>

                {/* What is this shit */}
                <WhatisthisShit />

            </div>

        </div>
    </WBIContext.Provider>
    )
}

export default App