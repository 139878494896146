import { useEffect, useContext } from "react"
import { WBIContext } from '../../App'
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"
import { auth } from "./firebase";

import veilIdeas from '../../js/modules/veilIdeas.js'
import clearSearch from '../../js/modules/clearSearch.js'

import questionMark from '../../images/info-black.svg'
import profileIcon from '../../images/profile.svg'

function Header (props) {
    const navigate = useNavigate()
    const location = useLocation()

    const {
      ideas,
      currentPage, setCurrentPage,
      common,
      revealAllState,
      allIdeasHidden,
      random, setRandom,
      searchInput, setSearchInput,
      setWhatisthisshit,
      setVeilDefault,
      setAllIdeasHidden,
      setRevealAllState,
      setFilteredIdeas,
      setpaginatedIdeas,
      setPaginatedIndex,
      paginated, setPaginated,
      sharing, setSharing,
      userPanelActive, setUserPanelActive,
      comingSoon
    } = useContext(WBIContext)

    const useParamsRoute = useParams()

    function hideAll () {
        veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
    }

    function websiteNameClickHandler () {
        clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
        setRandom(false)
        setUserPanelActive(false)
        hideAll()
    }

    function clearTagSearch () {
        clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
        navigate('/')
        setCurrentPage('/')
    }

    function randomClickHandler () {
        setRandom(current => !current)
        setSharing(false)
        hideAll()
        props.setRandoIdea()
        clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
        setCurrentPage('random')
    }

    function revealAll () {
        setVeilDefault(false)
        setRevealAllState(true)
        setAllIdeasHidden(false)
    }

    useEffect(() => { // Random button click
        if (random) {
            navigate('random')
            setCurrentPage('random')
            setUserPanelActive(false)
        } else {
            if (userPanelActive) {
                navigate('userpanel/login')
                setCurrentPage('login')
            }
            else if (!location.pathname.includes('ideas') && !location.pathname.includes('tags')) {
                // console.log('random useEffect')
                navigate('/')
                setCurrentPage('/')
            }
        }
    }, [random])

    useEffect(() => { // UserPanel button click
        if (userPanelActive) {
            navigate('userpanel/login')
            setCurrentPage('login')
            setRandom(false)
        } else {
            if (random) {
                navigate('random')
                setCurrentPage('random')
            }
            else if (!location.pathname.includes('ideas') && !location.pathname.includes('tags')) {
                navigate('/')
                setCurrentPage('/')
            }
        }
    }, [userPanelActive])

    useEffect(() => { // Refresh
        if (location.pathname.includes('random')) {
            setRandom(true)
            navigate('/random')
        } else if (location.pathname === 'login') {
                setCurrentPage('login')
                setUserPanelActive(true)
                setRandom(false)
                setSharing(false)
                setPaginated(false)
                navigate('userpanel/login')
        }
    }, [])

    // useEffect(() => {
    // }, [currentPage])

    const UserPanelIcon = () => {
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m0 0h24v24h-24z" fill="#fff" opacity="0" />
              <g fill="#231f20">
                <path d="m12 11a4 4 0 1 0 -4-4 4 4 0 0 0 4 4z" />
                <path d="m18 21a1 1 0 0 0 1-1 7 7 0 0 0 -14 0 1 1 0 0 0 1 1z" />
              </g>
            </svg>
        )
    }

    const HeaderSeperator = () => {
        return (<div className="header-seperator">&nbsp;|&nbsp;</div>)
    }

    return (
      <header>

        <Link to={'/'} onClick={() => {setCurrentPage('/')}} className="website-name-link">
            <h1 className="header-website-name" onClick={() => websiteNameClickHandler()}>{common['website-name']}</h1>
        </Link>

        <div className="site-panel">

            <div className="header-panel-buttons">
                <button className={(revealAllState && !allIdeasHidden) ? "active" : null} onClick={() => {revealAll()}}>Reveal All</button>
                &nbsp;
                <button className={(!revealAllState && allIdeasHidden) ? "active" : null} onClick={() => {hideAll()}}>Hide All</button>
            </div>

            <HeaderSeperator />

            <Link className="" to={'random'} ><button className={!random ? "random" : "random active"} onClick={randomClickHandler}>Random</button></Link>

            <HeaderSeperator />

            <div className="tag-search">
                <input type='text' placeholder='Filter by tag' onChange={(e) => {setSearchInput(e.currentTarget.value)}} value={searchInput}/>
                {searchInput !== '' ? <div className="close-cross" onClick={() => {clearTagSearch()}}></div> : null}
            </div>

            <HeaderSeperator />

            <button className="whatisthisshit" onClick={() => {setWhatisthisshit(current => !current)}}><img src={questionMark} className='' alt="" /></button>

            {
                comingSoon ?
                <>
                    <HeaderSeperator />
                    <button className={`${userPanelActive ? 'active' : '' } user-panel-btn`} onClick={() => {setUserPanelActive(current => !current)}}><UserPanelIcon /></button>
                </>
                :
                null
            }

        </div>

      </header>
      )
}

export default Header