import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { WBIContext } from '../../App'
import { auth } from './firebase.js'

import Idea from './Idea.js'

function Main (props) {
    const location = useLocation()
    const user = auth.currentUser;

    const {
            ideas,
            filteredIdeas,
            paginationAmount,
            paginatedIdeas,
            paginated, setPaginated,
            paginatedIndex,
            random, setRandom,
            randomIdea, setRandomIdea,
            currentPage,
            sharing, setSharing,
            userPanelActive, setUserPanelActive,
            firebaseDBLoaded
    } = useContext(WBIContext)

    const IdeasReturn = filteredIdeas.map((idea, index) => {
        if (index < paginationAmount) {
            return (
                    <Idea key={index} idea={idea}/>
                )
        } else {
            return false
        }
    })

    const PaginatedIdeasReturn = paginatedIdeas[paginatedIndex]?.map((idea, index) => {
        return (
            <Idea key={index} idea={idea} />
        )
    })

    const IdeaToShare = () => {
        if (currentPage !== '/') {
            var found = ideas.find((element) => element.urlName === currentPage)
            if (typeof found !== 'undefined') {
                return <Idea key={currentPage} idea={found} />
            }
        }
    }

    useEffect(() => {
        if (currentPage !== '/') { // Not Home
            setSharing(false)
            setPaginated(false)
            if (currentPage.includes('/tags/')) { // Tags in route
                setSharing(false)
            } else if (currentPage.includes('random')) { // Random
                var rndInt = Math.floor(Math.random() * (ideas.length - 1)) + 1
                setRandomIdea(ideas[rndInt])
                setRandom(true)
                setSharing(false)
            } else if (paginated) {
                setSharing(false)
                setRandom(false)
            } else if (location.pathname.includes('userpanel')) {
                setSharing(false)
            } else { // Idea share
                setSharing(true)
                setRandom(false)
            }
        } else { // Homepage
            setSharing(false)
        }
    }, [currentPage, setSharing, setRandomIdea, setPaginated, paginated, ideas, setRandom])

    return (
        <div className="main container">
            <div className="ideas">
            {/*<h1>Random: {random.toString()} <br /> Sharing:{sharing.toString()} <br /> Paginated: {paginated.toString()} </h1>*/}

                {/* Single Idea to share */}
                {(sharing && !random) && <IdeaToShare />}

                {/* Random Idea */}
                {(random && !sharing) && <Idea key={randomIdea} idea={randomIdea} />}

                {/* Filtered ideas(tag search) */}
                {(!random && !paginated && !sharing) && IdeasReturn}

                {/* All ideas with pagination */}
                {(!random && paginated && !sharing) && PaginatedIdeasReturn}
            </div>
        </div>
    )
}

export default Main