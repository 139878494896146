import { useState, useEffect, useContext } from "react"
import { Link, useLocation } from "react-router-dom";
import { WBIContext } from '../../App'

import IdeaImageEnlarge from './Idea-Image-Enlarge.js'

import loadingIcon from '../../images/loading.gif'

import veilIdeas from '../../js/modules/veilIdeas.js'

function Idea (props) {
    const location = useLocation()

    const {
      veilDefault, setVeilDefault,
      allIdeasHidden, setAllIdeasHidden,
      searchInput, setSearchInput,
      setRevealAllState,
      contributors,
      currentPage, setCurrentPage,
      sharing, setSharing,
      setRandom
    } = useContext(WBIContext)

    const [veiled, setVeiled] = useState(true)
    const [enlarge, setEnlarge] = useState(false)
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [enlargedImagesLoaded, setEnlargedImagesLoaded] = useState(false)
    const [clipboardCopyActive, setClipboardCopyActive] = useState(false)
    const [URLCopiedActive, setURLCopiedActive] = useState(false)

    useEffect(() => {
        if (!veilDefault) {
            setVeiled(false)
        } else {
            setVeiled(true)
        }
    }, [veilDefault])

    useEffect(() => {
        if (allIdeasHidden && !veiled) {
            setVeiled(true)
        }
    }, [setAllIdeasHidden, allIdeasHidden])

    useEffect(() => {
        if (!allIdeasHidden && veiled) {
            setVeiled(false)
        }
    }, [veiled])

    function nameClickHandler () {
        setVeiled(current => !current)
        setAllIdeasHidden(false)
    }

    function tagClickHandler (tag) {
        setSearchInput(tag)
        veilAll()
        setSharing(false)
        setAllIdeasHidden(true)
    }

    function veilAll () {
        veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
    }

    useEffect(() => {
        if (location.pathname.includes('ideas')) {
            setSearchInput('')
            setSharing(true)
            setRandom(false)
        }
    }, [currentPage])

    function imageCreditReturn (credit, link) {
        if (credit !== '' && link !== '') {
            return (
                <div>
                    Image credit: <a href={link} target="_blank" rel="noreferrer"><strong>{credit}</strong></a>
                </div>
            )
        } else if (credit !== '') {
            return (
                <div>
                    Image credit: <strong>{credit}</strong>
                </div>
            )
        }
    }

    const ideaCreditsReturn = props.idea['idea-credits'].map( (creditItem, creditIndex) => {
        var nameSet = creditItem.name
        var linkSet = creditItem.link

        contributors.forEach((contrItm, itmIndex) => {
            // console.log(contrItm.webname, creditItem.name)
            if (contrItm.webname === creditItem.name) {
                nameSet = contrItm.name
                if (creditItem.link === '') {
                    if (contrItm.link === '') {
                        linkSet = ''
                    } else {
                        linkSet = contrItm.link
                    }
                }
            }
        })

        if (linkSet !== '') {
            return (<div key={creditIndex}><a href={linkSet} target="_blank"><strong>{nameSet}</strong>&nbsp;</a></div>)
        } else {
            return (<div key={creditIndex}><strong>{nameSet}</strong>&nbsp;</div>)
        }

    })

    function copyToClipboard () {
        var url = document.location.href
        var copyText = url
        navigator.clipboard.writeText(copyText);
        setClipboardCopyActive(true)
        setURLCopiedActive(true)
    }

    return (
        <div className="idea">

          <p className={`${sharing ? 'share-title' : ''} idea-para`}>{props.idea.description}</p>

          <h2 className={`${veiled ? 'veiled' : '' } idea-name`} onClick={() => {nameClickHandler()}} >{props.idea.name}</h2>

          <div className="idea-credit">
              <div>
                  {
                    props.idea['idea-credits'].length > 1
                    ?
                        <strong>Credits:&nbsp;</strong>
                    :
                        <strong>Credit:&nbsp;</strong>
                  }
              </div>
              {ideaCreditsReturn}
          </div>

          {props.idea.images.length !== 0 ?
            <div className="idea-image-wrapper">
                {props.idea.images.map( (imgItem, imgIndex) => {
                    if (imgItem.src !== '') {
                        return (
                          <div key={imgIndex}>
                              <div className={
                                  `${enlargedImagesLoaded ? 'image-loaded' : null}
                                  idea-image-inner`}>

                                  {/* View Image Button */}
                                  {!imagesLoaded ?
                                      <div className="loading-wrapper">
                                          <img src={loadingIcon} className='loading-image' alt="" />
                                          <h4>Loading Image...</h4>
                                      </div>
                                   : <button className={`${(enlarge) ? 'active' : null}`} onClick={() => {setEnlarge(current => !current)}}>View Image</button>}

                                   {/* Image Lightbox */}
                                  <IdeaImageEnlarge imgItem={imgItem} onLoad={() => {setImagesLoaded(true)}} setImagesLoaded={setImagesLoaded} setEnlarge={setEnlarge} enlarge={enlarge} setEnlargedImagesLoaded={setEnlargedImagesLoaded} />

                              </div>

                              {/* Image Credits */}
                              <div className="image-credit">
                                  {imageCreditReturn(imgItem['image-credit'], imgItem.link)}
                              </div>
                          </div>
                        )
                    } else {
                        return false
                    }
                    })}
            </div>
            : null}

           <div className="idea-tags">
                <h3>Tags</h3>
               {props.idea.tags.map( (tagItem, tagIndex) => {
                    return (
                        <Link to={'/tags/:' + tagItem} onClick={() => {setCurrentPage('/')}} key={tagIndex}>
                            <button className="flat-btn" onClick={() => {tagClickHandler(tagItem)}}>{tagItem}</button>
                        </Link>
                    )
                })}
           </div>

           {sharing ? <hr/> : null}

           {
            !sharing ?
            <div className="share-wrapper">
                <h3>Share</h3>
                <Link to={'/ideas/:' + props.idea.urlName} onClick={() => {setCurrentPage(props.idea.urlName)}}>
                    <button className="flat-btn">Share this idea</button>
                </Link>
            </div>
            :
            <div className="share-wrapper sharing-mode">
                <div className="tooltip-wrapper">
                    <button className={`${URLCopiedActive ? 'active' : ''}`} onClick={() => copyToClipboard()}>
                        {URLCopiedActive ? "Copied" : "Copy"} to clipboard
                    </button>
                    <div className={`${clipboardCopyActive ? 'active' : '' } tooltip-text`} onAnimationEnd={() => setClipboardCopyActive(false)}>
                    <div className="arrow-left"></div>
                        Copied to clipboard
                    </div>
                </div>
                <Link to={'/'} className="back-btn" onClick={() => {setCurrentPage('/')}}>
                  <button className="flat-btn">Back to home</button>
                </Link>
            </div>
           }

           {!sharing ? <hr/> : null}

        </div>
    )
}

export default Idea