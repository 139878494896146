import { useEffect, useContext, useState } from "react"
import { useNavigate, useLocation, Link } from "react-router-dom";
import { WBIContext } from '../../App'
import { auth } from './firebase.js'

import Login from './Login.js'
import Signup from './Signup.js'
import Profile from './Profile.js'

import LoadingElement from './Loading-Element.js'

function UserPanel (props) {
    const navigate = useNavigate()
    const user = auth.currentUser;

    const {
      userPanelActive, setUserPanelActive,
      userLoggedIn, setUserLoggedIn,
      firebaseDBLoaded,
      signup, setSignup,
      login, setLogin,
      signout, setSignout,
      comingSoon
    } = useContext(WBIContext)

    useEffect(() => {
        if (firebaseDBLoaded) {
            if (user) {
                setUserLoggedIn(true)
            } else {
                setUserLoggedIn(false)
                setLogin(true)
                setSignup(false)
                setSignout(false)
            }
        }
    }, [firebaseDBLoaded])

    function UserPanelDisplay () {
        if (userLoggedIn) {
            return <Profile />
        }
        else if (signup) {
            return <Signup />
        }
        else if (login) {
            return <Login />
        }
    }

    function userPanelToggle (stateParam) {
        if (stateParam === 'signup') {
            setSignup(true)
            setLogin(false)
            setSignout(false)
        } else if (stateParam === 'login') {
            setLogin(true)
            setSignup(false)
            setSignout(false)
        }
    }

    function UserPanelBtns () {
        if (signup) {
            return (
                <Link
                    className=""
                    to={'userpanel/login'}
                >
                    <button className="" onClick = {() => userPanelToggle('login')}>Log In</button>
                </Link>
            )
        }
        if (login) {
            return (
                <Link
                    className=""
                    to={'userpanel/signup'}
                >
                    <button className="" onClick = {() => userPanelToggle('signup')}>Sign Up</button>
                </Link>
            )
        }
    }

    function ComingSoonReturn () {
        return (
            <div className="coming-soon">
                <h1>Under Construction</h1>
                <h3>Features:</h3>
                <ul>
                    <li>User Submissions</li>
                    <li>User Idea Notepad</li>
                </ul>
            </div>
        )
    }

    function UserPanelBody () {
        return (
            <>
                {!firebaseDBLoaded ? <div className="loggingin-loader"><LoadingElement loadingText="Logging In"/></div> : null}

                {firebaseDBLoaded ? <UserPanelDisplay /> : null}

                <div className="user-panel-buttons">
                {!userLoggedIn ? <UserPanelBtns /> : null}
                </div>
            </>
        )
    }

    return (
        <div className={`${userPanelActive ? '' : ''} user-panel-container`}>
            <div className="user-panel-body">
                {comingSoon ? <ComingSoonReturn /> : <UserPanelBody />}
            </div>
        </div>
    )
}

export default UserPanel