import { useContext } from "react"
import { WBIContext } from '../../App'

import veilIdeas from '../../js/modules/veilIdeas.js'
import clearSearch from '../../js/modules/clearSearch.js'

import anotherOneSound from '../../audio/another-one.mp3'

import { useGlobalAudioPlayer } from 'react-use-audio-player';

function RandomPage (props) {
  const { load } = useGlobalAudioPlayer()

    const {
      ideas,
      setVeilDefault,
      setAllIdeasHidden,
      setRevealAllState,
      setRandomIdea,
      setSearchInput,
      setFilteredIdeas,
      setPaginated,
      setpaginatedIdeas,
      setPaginatedIndex,
      soundMuted, setSoundMuted
    } = useContext(WBIContext)

    function hideAll () {
        veilIdeas(setVeilDefault, setAllIdeasHidden, setRevealAllState)
    }

    function setRandoIdea () {
      var rndInt = Math.floor(Math.random() * (ideas.length - 1)) + 1
      setRandomIdea(ideas[rndInt])
    }

    function anotherRandomOneClickHandler () {
        hideAll()
        setRandoIdea()
        clearSearch(ideas, setSearchInput, setFilteredIdeas, setpaginatedIdeas, setPaginatedIndex, setPaginated)
    }

    function playsound () {
        anotherRandomOneClickHandler()
        if (soundMuted) {
            load(anotherOneSound, {
              autoplay: true,
              volume: 0
            })
        } else {
            load(anotherOneSound, {
              autoplay: true,
              volume: 1
            })
        }
    }

    function muteSoundHandler () {
        setSoundMuted(current => !current)
        if (!soundMuted) {
            load(anotherOneSound, {
              stop: true,
              volume: 0
            })
        }
    }

    return (
        <>
          <div className="random-next">
              <button className={"non-toggle random-next-btn"} onClick={() => {playsound()}}>Another Random One</button>
              <button className={`${soundMuted ? 'active' : '' } mute-btn`} onClick={muteSoundHandler}>
                  {soundMuted ? "Unmute" : "Mute"}
              </button>
          </div>
        </>
      )
}

export default RandomPage