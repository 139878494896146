import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import UserPanel from './js/components/UserPanel'
import reportWebVitals from './reportWebVitals'
import ideasData from './js/data/ideas'
import auth from './js/components/firebase'
// import { getAuth } from "firebase/auth";

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getDatabase } from "firebase/database"
// import { getAnalytics } from "firebase/analytics"

// const firebaseConfig = {
//   apiKey: "AIzaSyCkI_LSh_fRTt4z_8EMGwvil30zVvufth4",
//   authDomain: "wordplayideasdb.firebaseapp.com",
//   projectId: "wordplayideasdb",
//   storageBucket: "wordplayideasdb.appspot.com",
//   messagingSenderId: "819907006246",
//   appId: "1:819907006246:web:a4d763e761b445e65abbe7",
//   measurementId: "G-R0MTHLEGD7", // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//   databaseURL: "https://wordplayideasdb-default-rtdb.firebaseio.com/"
// }

// Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const auth = getAuth(app);
// const analytics = getAnalytics(app)
// const database = getDatabase()
// ====== Firebase end

var rndInt = Math.floor(Math.random() * (ideasData.ideas.length - 1)) + 1

var pagesArray = [
    {
      path: "/",
      element: <App routeParam='element-zero'/>,
      errorElement: <App page={ideasData.common["page-not-found-url"]} routeParam='error'/>,
      children: [
        {path: "random", element: <App page={ideasData.ideas[rndInt]} routeParam='random' />}, // Random route needs to be in place if user lands on random
        {path: "/tags/:id", element: <App routeParam='tags' />},
        {path: "/ideas/:id", element: <App routeParam='ideas' />},
        {path: "userpanel", element: <App routeParam='userpanel' />},
        {path: "userpanel/login", element: <App routeParam='login' />},
        {path: "userpanel/profile", element: <App routeParam='profile' />},
        {path: "userpanel/signup", element: <App routeParam='signup' />}
      ]
    }
  ]

const router = createBrowserRouter(pagesArray);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
