import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCkI_LSh_fRTt4z_8EMGwvil30zVvufth4",
    authDomain: "wordplayideasdb.firebaseapp.com",
    projectId: "wordplayideasdb",
    storageBucket: "wordplayideasdb.appspot.com",
    messagingSenderId: "819907006246",
    appId: "1:819907006246:web:a4d763e761b445e65abbe7",
    measurementId: "G-R0MTHLEGD7", // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    databaseURL: "https://wordplayideasdb-default-rtdb.firebaseio.com/"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }