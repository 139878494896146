import { useEffect, useContext } from "react"
import { WBIContext } from '../../App'

function WhatisthisShit (props) {
    const {
      whatisthisshit, setWhatisthisshit
    } = useContext(WBIContext)

    useEffect(() => {
    }, [])

    return (
        <div className={`${whatisthisshit ? '' : 'invisible'} whatisthisshit-container lightbox`} onClick={() => setWhatisthisshit(false)}>

        <div className="whatisthisshit-body">
            <h1>What is this shit?</h1>

            <p>Me and my buddies contrive business ideas from words and phrases and now it's a website.</p>

            <p>You can email any ideas or images you want to submit to <a href="mailto:moehammoud1@gmail.com">moehammoud1@gmail.com</a></p>
        </div>

        <div className="close-cross close-enlarged-image"></div>
        </div>
    )
}

export default WhatisthisShit