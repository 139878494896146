import { createContext, useState, useEffect, useContext } from "react"
import { WBIContext } from '../../App'
import ideasData from '../../js/data/ideas'

import Main from './Main.js'
import Pagination from './Pagination.js'
import RandomPage from './Random-Page.js'

function IdeasBody (props) {
    const {
      userPanel, setUserPanel,
      random,
      sharing,
      searchInput
    } = useContext(WBIContext)

    // useEffect(() => {}, [])

    return (
        <>
            {/* Pagination Top */}
            {(searchInput.length === 0 && !random && !sharing && (props.page !== ideasData.common["page-not-found-url"])) ? <Pagination paginate={true}/> : null}
            {/* Random */}
            {(searchInput.length === 0 && random && (props.page !== ideasData.common["page-not-found-url"])) ? <RandomPage /> : null}
            {/* Ideas */}
            {(props.page !== ideasData.common["page-not-found-url"]) && <Main page={props.page} />}
            {/* Pagination Bottom*/}
            {(searchInput.length === 0 && !random && !sharing && (props.page !== ideasData.common["page-not-found-url"])) ? <Pagination paginate={false}/> : null}
        </>
    )
}

export default IdeasBody