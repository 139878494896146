import { useEffect, useContext, useState } from "react"
import { WBIContext } from '../../App'
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { getDatabase} from "firebase/database"

import ideasData from '../data/ideas'


function Profile (props) {
    const [userInfo, setUserInfo] = useState(ideasData.users)

    const {
      currentPage, setCurrentPage,
      userLoggedIn, setUserLoggedIn
    } = useContext(WBIContext)

    const navigate = useNavigate();
    const user = auth.currentUser;

    const logoutUser = async (e) => {
        e.preventDefault();

        await signOut(auth);
        setUserLoggedIn(false)
        navigate("/userpanel/login")
    }

    useEffect(() => {
        if (user) {
            setUserLoggedIn(true)
        }
    }, [])

    useEffect(() => {
        const db = getDatabase()
        // console.log(user)
        // console.log(user.uid)
        // console.log(userInfo)
        console.log(userInfo[user.uid])
    }, [userLoggedIn])

    const ProfileElement = () => {
        if (user) {
            return (
                <div className="container">
                    <div className="">
                        <h2>Welcome <em className="">{ user.email }</em>. You are logged in!</h2>
                        &nbsp;

                        <h3><strong>User Name:</strong> {userInfo[user.uid].username}</h3>
                        <h3><strong>Email:</strong> {userInfo[user.uid].email}</h3>
                        &nbsp;

                        <div className="">
                            <button type="submit" className="" onClick={(e) => logoutUser(e)}>Logout</button>
                        </div>
                    </div>
                </div>
                )
        }
    }

    return(
        <ProfileElement />
    )

}

export default Profile