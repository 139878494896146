import React, { useState, useContext } from "react";
import { WBIContext } from '../../App'
import { auth } from "./firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

function Signup (props) {
    const {
      currentPage, setCurrentPage
    } = useContext(WBIContext)

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [notice, setNotice] = useState("");

        const signupWithUsernameAndPassword = async (e) => {
            e.preventDefault();

            if (password === confirmPassword) {
                try {
                    await createUserWithEmailAndPassword(auth, email, password);
                    navigate("/userpanel/profile");
                } catch {
                    setNotice("Sorry, something went wrong. Please try again.");
                }
            } else {
                setNotice("Passwords don't match. Please try again.");
            }
        };

        return(
            <div className="container form-wrapper">
                <h1>Sign Up</h1>

                <form className="">

                    { "" !== notice &&
                        <div className="alert alert-warning" role="alert">
                            { notice }
                        </div>
                    }

                    <div className="input-wrapper">
                        <label htmlFor="signupEmail" className="form-label">Enter an email address for your username</label>
                        <input id="signupEmail" type="email" className="form-control" aria-describedby="emailHelp" placeholder="name@example.com" value={ email } onChange={ (e) => setEmail(e.target.value) }></input>
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="signupPassword" className="form-label">Password</label>
                        <input id="signupPassword" type="password" className="form-control" placeholder="Password" value={ password } onChange={ (e) => setPassword(e.target.value) }></input>
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                        <input id="confirmPassword" type="password" className="form-control" placeholder="Confirm Password" value={ confirmPassword } onChange={ (e) => setConfirmPassword(e.target.value) }></input>
                    </div>

                    <button type="submit" className="" onClick={(e) => signupWithUsernameAndPassword(e)}>Signup</button>

                    <div className="signup-link">
                        <span>Go back to login? <Link to="/">Click here.</Link></span>
                    </div>
                </form>

            </div>
        )

}

export default Signup