import { useContext } from "react"
import { WBIContext } from '../../App'
import { useParams } from "react-router-dom";
import ideasData from '../../js/data/ideas'

import ErrorElement from './Error-Element.js'

function IdeasHeader (props) {
    const {
      ideas,
      searchInput,
      random,
      sharing,
      results,
      filteredIdeas,
      firebaseDBLoaded,
      userPanelActive
    } = useContext(WBIContext)

    const useParamsRoute = useParams()

    function confirmParamsRoute (idea) {
        if (Object.keys(useParamsRoute).length !== 0) { // URL has route parameter
            if (useParamsRoute.id) {
                if (window.location.pathname.includes('/ideas/:')) {
                    if (ideas.every(confirmIdea)) {
                        return false
                    }
                }
            }
        }
    }

    function confirmIdea (idea, moduleRouteParam) { // Confirm if url matches any idea urlName
        const moduleRoute = useParamsRoute.id.split(":")[1]
        return idea.urlName !== moduleRoute;
    }

    if (props.page !== ideasData.common["page-not-found-url"]) {

        if (userPanelActive) {
            return (<><h1>User Panel</h1> </>)
        } else {
            if (random) {
                return (<h1>Random</h1>)
            } else {
                if (searchInput.length === 0 && !sharing) {
                    return (<h1>Latest</h1>)
                }
                else if (searchInput.length > 0 && results.length === 0) {
                    return (<h1>Filter by Tag: 0&nbsp;Results (Showing&nbsp;all)</h1>)
                }
                else if (searchInput.length > 0 && !sharing && results.length > 0) {
                    return (<h1>Filter by Tag: {filteredIdeas.length} Results</h1>)
                }
                else if (searchInput.length === 0 && sharing && !confirmParamsRoute() && typeof confirmParamsRoute() !== 'undefined' && firebaseDBLoaded) {
                    return <ErrorElement />
                }
                else if (searchInput.length === 0 && sharing) {
                    return (<><h1>Share Idea</h1> </>)
                }
            }
        }

    } else if (props.page === ideasData.common["page-not-found-url"]) {
      return <ErrorElement />
    } else {
      return (
          <div>
              Tags
          </div>
      )
    }

}

export default IdeasHeader