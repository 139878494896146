import { useEffect, useContext } from "react"
import { WBIContext } from '../../App'
import { Link } from "react-router-dom";

function ErrorElement (props) {
    const {
      currentPage, setCurrentPage
    } = useContext(WBIContext)

    return (
      <div className="page-idea-not-found">
        <h1>Sorry!<br/> Page or idea cannot be found.</h1>
        <Link to={'/'}  onClick={() => {setCurrentPage('/')}}>
          <button className="back-btn flat-btn">Back</button>
        </Link>
      </div>
      )
}

export default ErrorElement