import { useEffect, useContext, useState } from "react"
import { auth } from './firebase.js'
import { WBIContext } from '../../App'
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import LoadingElement from './Loading-Element.js'

function Login (props) {
    const {
      currentPage, setCurrentPage,
      userLoggedIn, setUserLoggedIn,
      loggingIn, setLoggingIn
    } = useContext(WBIContext)

    const navigate = useNavigate()
    const user = auth.currentUser;

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [notice, setNotice] = useState("")

    const loginWithUsernameAndPassword = async (e) => {
        setLoggingIn(true)
        e.preventDefault()

        try {
            await signInWithEmailAndPassword(auth, email, password)
            setUserLoggedIn(true)
            setLoggingIn(false)
            navigate("/userpanel/profile")
        } catch {
            setLoggingIn(false)
            setNotice("You entered a wrong username or password.")
        }
    }

    // useEffect(() => {
    // }, [])

    // useEffect(() => {
    // }, [setUserLoggedIn])

    return(
        <div className="container form-wrapper">
            <h1>Log In</h1>

            {
                loggingIn ?
                <LoadingElement loadingText="Logging In"/>
                :
                <form className="">
                    { "" !== notice &&
                        <div className="alert alert-warning" role="alert">
                            { notice }
                        </div>
                    }

                    <div className="input-wrapper">
                        <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="name@example.com" value={ email } onChange={ (e) => setEmail(e.target.value) }></input>
                    </div>

                    <div className="input-wrapper">
                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" value={ password } onChange={ (e) => setPassword(e.target.value) }></input>
                    </div>

                    <button type="submit" className="" onClick={(e) => loginWithUsernameAndPassword(e)}>Submit</button>

                    <div className="signup-link">
                        <span>Need to sign up for an account? <Link to="./signup">Click here.</Link></span>
                    </div>
                </form>
            }

        </div>
    )

}

export default Login