let ideasData = {
    "common": {
        "website-name": "WORDPLAY BASED BUSINESS IDEAS",
        "paginationAmount": 10,
        "page-not-found-url": "page-not-found"
    },
    "contributors": [
        {"name": "MHC", "link": "https://wordplayideas.com", "webname": "mh"},
        {"name": "Chris Antony", "link": "", "webname": "chris-antony"},
        {"name": "Joshua Maessen", "link": "", "webname": "joshua-maessen"},
        {"name": "Eric", "link": "", "webname": "eric"},
        {"name": "Shane D'Silva", "link": "https://www.instagram.com/picklednoodles", "webname": "shane"}
    ],
    "users": {
        "pmC5Jm6df1SWO8cp2Yasl67JFKo2" : {
            "uid": "pmC5Jm6df1SWO8cp2Yasl67JFKo2",
            "username": "Moeh",
            "email": "moeh@blackmagicdesign.com",
            "ideapad": [
                {
                    "name": "Idea 1 Name",
                    "description": "Idea 1 Description",
                    "urlName": "idea-1-description",
                    "idea-credits": [{"name": "mh", "link": ""}],
                    "images": [
                        {
                            "src":  "https://placehold.co/500x500?text=Your+Image+Here",
                            "alt": "",
                            "image-credit": "Your Name Here with a link",
                            "link": "https://wordplayideas.com/"
                        }
                    ],
                    "tags": ["pure-pun", "table-top"],
                    "hidden-tags": ["guildy", "as", "charged"]
                },
                {
                    "name": "Idea 2 Name",
                    "description": "Idea 2 Description",
                    "urlName": "idea-2-description",
                    "idea-credits": [{"name": "mh", "link": ""}],
                    "images": [
                        {
                            "src":  "https://placehold.co/500x500?text=Your+Image+Here",
                            "alt": "",
                            "image-credit": "Your Name Here with a link",
                            "link": "https://wordplayideas.com/"
                        }
                    ],
                    "tags": ["pure-pun", "table-top"],
                    "hidden-tags": ["guildy", "as", "charged"]
                }
            ]
        },
        "usernfmh" : {
            "uid": "usernfmh",
            "username": "testuser1",
            "email": "testuser1@blackmagicdesign.com",
            "ideapad": []
        }
    },
    "ideas": [
        {
            "name": "Guildy as Charged",
            "description": "A digital table top company specialising in guild based gameplay.",
            "urlName": "a-digital-table-top-company-specialising-in-guild-based-gameplay",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src":  "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": "https://wordplayideas.com/"
                }
            ],
            "tags": ["pure-pun", "table-top"],
            "hidden-tags": ["guildy", "as", "charged"]
        },
        {
            "name": "All Torque",
            "description": "A really cocky drag race sponsor.",
            "urlName": "a-really-cocky-drag-race-sponsor",
            "idea-credits": [{"name": "eric", "link": ""}, {"name": "mh", "link": ""}],
            "images": [
                {
                    "src":  "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": "https://wordplayideas.com/"
                }
            ],
            "tags": ["pure-pun", "racing", "sport"],
            "hidden-tags": ["all", "torque"]
        },
        {
            "name": "Russell Brand's Mussell Bran",
            "description": "Russell Brand branded high fibre seafood cereal.",
            "urlName": "russell-brand-branded-high-fibre-seafood-cereal",
            "idea-credits": [{"name": "chris-antony", "link": ""}],
            "images": [
                {
                    "src":  "https://i.imgur.com/ROlireq.jpeg",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": "https://wordplayideas.com/"
                }
            ],
            "tags": ["rhyme", "food", "celebrity"],
            "hidden-tags": ["russell", "mussell", "bran"]
        },
        {
            "name": "Crocodile High Club",
            "description": "A commercial airline that transports crocodiles.",
            "urlName": "a-commercial-airline-that-transports-crocodiles",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "https://imgur.com/HltKvCx.jpeg",
                    "alt": "",
                    "image-credit": "Your Name Here without a link",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "chartering"],
            "hidden-tags": ["crocodile", "high", "club"]
        },
        {
            "name": "The Circle of Knife",
            "description": "A slaughter house that specialises in lions.",
            "urlName": "a-slaughter-house-that-specialises-in-lions",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here without a link",
                    "link": ""
                }
            ],
            "tags": ["pop-culture-references", "disney"],
            "hidden-tags": ["the", "circle", "of", "knife", "lion", "king"]
        },
        {
            "name": "The steaks are high",
            "description": "A steak cooking competition where the competitors get baked before hand.",
            "urlName": "a-steak-cooking-competition-where-the-competitors-get-baked-before-hand",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here without a link",
                    "link": ""
                }
            ],
            "tags": ["pure-pun", "competition"],
            "hidden-tags": ["the", "high", "steaks", "are"]
        },
        {
            "name": "Fronts End Development",
            "description": "A coder that launders money for online mob run businesses",
            "urlName": "a-coder-that-launders-money-for-online-mob-run-businesse",
            "idea-credits": [
                {"name": "mh", "link": ""},
                {"name": "joshua-maessen", "link": ""},
                {"name": "chris-antony", "link": ""}
            ],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["website", "marketing"],
            "hidden-tags": ["fronts", "end", "development"]
        },
        {
            "name": "PrySee",
            "description": "A really expensive crowbar",
            "urlName": "a-really-expensive-crowba",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["pure-pun", "tools"],
            "hidden-tags": ["prysee"]
        },
        {
            "name": "Dubloons",
            "description": "A circus troupe based in Dublin.",
            "urlName": "a-circus-troupe-based-in-dublin",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+(No+Enlarged+Image)",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": ""
                }
            ],
            "tags": ["wordplay", "entertainment"],
            "hidden-tags": ["dubloons"]
        },
        {
            "name": "Skiwi",
            "description": "A New Zealand based ski resort.",
            "urlName": "a-new-zealand-based-ski-resort",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "resort"],
            "hidden-tags": ["skiwi"]
        },
        {
            "name": "Crowe Bar",
            "description": "A bar owned by Russell Crowe",
            "urlName": "a-bar-owned-by-russell-crow",
            "idea-credits": [{"name": "mh", "link": ""}],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["pure-pun", "hospitality", "celebrity"],
            "hidden-tags": ["crowe", "bar", "russell"]
        },
        {
            "name": "GheeWi",
            "description": "A New Zealand based ghee manufacturing company.",
            "urlName": "a-new-zealand-based-ghee-manufacturing-company",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "manufacturing"],
            "hidden-tags": ["gheewi"]
        },
        {
            "name": "SmorgasHorde",
            "description": "An all you can eat buffet establishment that only serves 30-100 person groups.",
            "urlName": "an-all-you-can-eat-buffet-establishment-that-only-serves-30-100-person-groups",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+Here",
                    "alt": "",
                    "image-credit": "Your Name Here with a link",
                    "link": ""
                }
            ],
            "tags": ["hospitality", "rhyme"],
            "hidden-tags": ["smorgashorde"]
        },
        {
            "name": "CompletionTrysts",
            "description": "A dating site for completionists to meet up.",
            "urlName": "a-dating-site-for-completionists-to-meet-up",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/300x100?text=Your+Image+(No+Enlarged+Image)",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "online", "dating"],
            "hidden-tags": ["completiontrysts", "trysts"]
        },
        {
            "name": "KhanTrast",
            "description": "A website presenting rulers and how they differ from Genghis Khan.",
            "urlName": "a-website-presenting-rulers-and-how-they-differ-from-genghis-khan",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+(No+Enlarged+Image)",
                    "alt": "",
                    "image-credit": "Your Name here without a link",
                    "link": ""
                }
            ],
            "tags": ["pun", "website"],
            "hidden-tags": ["khantrast"]
        },
        {
            "name": "AvaCargo",
            "description": "A charter company that handles and transports Avacados",
            "urlName": "a-charter-company-that-handles-and-transports-avacado",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "chartering"],
            "hidden-tags": ["ava", "cargo"]
        },
        {
            "name": "Knot Here For Laughs",
            "description": "A chartering company that doesn't fuck around.",
            "urlName": "a-chartering-company-that-doesn-t-fuck-around",
            "idea-credits": [{"name": "Joshua Maessen", "link": ""}],
            "images": [
                {
                    "src": "https://placehold.co/500x500?text=Your+Image+(No+Enlarged+Image)",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["pun", "chartering"],
            "hidden-tags": ["Knot", "Here", "For", "Laughs"]
        },
        {
            "name": "Peas Don't Leaf",
            "description": "A fresh produce company that is really insecure.",
            "urlName": "a-fresh-produce-company-that-is-really-insecure",
            "idea-credits": [{"name": "Joshua Maessen", "link": ""}],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["pun", "chartering"],
            "hidden-tags": ["Peas", "Dont", "Leaf"]
        },
        {
            "name": "Laser Bear Hair Removal",
            "description": "If there's a lot of bear hair, in your house somewhere, who you gonna call?",
            "urlName": "if-there-s-a-bear-hair-in-your-house-somewhere-who-you-gonna-call",
            "idea-credits": [{"name": "", "link": ""}],
            "images": [
                {
                    "src": "",
                    "alt": "",
                    "image-credit": "",
                    "link": ""
                }
            ],
            "tags": ["rhyme", "pest-control"],
            "hidden-tags": ["Laser", "Bear", "Removal"]
        }
    ],
    "pitches": [
        {
            "name": "Star Anus",
            "description": "",
            "credit": ""
        },
        {
            "name": "Laser Bear Removal",
            "description": "If there's a bear, in your house somewhere, who you gonna call?",
            "credit": ""
        },
        {
            "name": "Knot Youre Frends",
            "description": "An illiterate private jet company that is really exclusive.",
            "credit": "Joshua Maessen"
        },
        {
            "name": "Steel Can't Here",
            "description": "A really bad audiologist.",
            "credit": "Joshua Maessen"
        },
        {
            "name": "Test Idea 1",
            "description": "Test 1 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test Idea 2",
            "description": "Test 2 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test Idea 3",
            "description": "Test 3 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test Idea 4",
            "description": "Test 4 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test Idea 5",
            "description": "Test 5 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test 6",
            "description": "Test 6 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        },
        {
            "name": "Test 7",
            "description": "Test 7 Lorem Ipsum but not the schmipsum.",
            "urlName": "idea-name-url",
            "idea-credits": [{"name": '', "link": ""}],
            "images": [
                {
                    "src": '', alt: '',
                    "image-credit": '',
                    "link": ''
                }
            ],
            "tags": ['test'],
            "hidden-tags": ["test"]
        }
    ]
}

export default ideasData;