import { useEffect, useContext } from "react"
import { WBIContext } from '../../App'

function IdeaImageEnlarge (props) {
    const {
      whatisthisshit
    } = useContext(WBIContext)

    useEffect(() => {
        if (props.enlarge === true || whatisthisshit === true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "visible"
        }
    }, [props.enlarge, whatisthisshit])

    function imageLoadedHandler () {
        props.setEnlargedImagesLoaded(true)
        props.setImagesLoaded(true)
        // console.log(props.imgItem.src)
    }

    return (
        <div className={`${props.enlarge ? '' : 'invisible'} lightbox`} onClick={() => {props.setEnlarge(false)}}>
            <img src={props.imgItem.src} className='' alt={props.imgItem.alt} onLoad={() => {imageLoadedHandler()}}/>
            <div className="close-cross close-enlarged-image"></div>
        </div>
    )
}

export default IdeaImageEnlarge